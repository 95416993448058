<template>
  <div class="content-box">
    <div class="cont-header">
      <a-row>
        <a-col :span="1"></a-col>
        <a-col :span="11">
            <span :selected="selType==2" @click="onClickHeader(2)" class="header_link">可使用</span>
            <span :selected="selType==3" @click="onClickHeader(3)" style="margin-left:10%;" class="header_link">已使用</span>
            <span :selected="selType==4" @click="onClickHeader(4)" style="margin-left:10%;" class="header_link">已过期</span>
        </a-col>
        <a-col :span="12" style="text-align:right;padding-right:5%;">
          <a-col :span="8" style="cursor:pointer;" @click="ruleDesc">
            <img src="@/assets/images/web/coupon/use.svg"/> 
            <span style="color: #333333;font-weight:400;">&nbsp;使用规则</span>
          </a-col>
          <a-col :span="16" style="padding-left:5%;" :class="selType==2?'':'coupon-gray'">
              <a-col :span="20">
                <input :disabled="selType!=2" type="text" class="search-input" placeholder="请输入优惠券兑换码" style="width:100%;height:33px;border-radius: 2px 0px 0px 2px;border: 1px solid #E0E0E0;outline: none;cursor:pointer;" v-model = "exchangeValue" @keydown.enter="onExchange" />
              </a-col>
              <a-col :span="4">
                <a-button style="background: #FF7B13;border-radius: 0px 2px 2px 0px;border: 1px solid #FF7B13;color: #FFFFFF;" :disabled="selType!=2"  @click="onExchange">
                  兑换
                </a-button>
              </a-col>
          </a-col>
        </a-col>
      </a-row> 
    </div>
    <div class="cont">
      <coupon-list  :requestData="requestData"></coupon-list>
      <MoreData @dataChange='dataChange' :params='couponListParams' :isMore=true :request='$axios.CouponList'></MoreData>
    </div>
    <DialogCouponRule  @close='showCouponRule=false' :visible="showCouponRule"></DialogCouponRule>
  </div>
</template>

<script>
  let AllOptions = [{label: '待使用优惠券', value: '2'},{label: '已使用优惠券', value: '3'},{label: '已过期优惠券', value: '4'}]
  import DialogCouponRule from './components/DialogCouponRule.vue'
  import CouponList from './components/CouponList.vue'
  import MoreData from './components/MoreData';
  export default {
    data() {
      return {
        selType:2,
        showCouponRule:false,
        requestData:[],
        couponListParams: {},
        exchangeValue:'', //兑换码
      }
    },
    components: {
      DialogCouponRule,
      CouponList,
      MoreData,
    },

    created() {
      let params = {};
      params.page_size = '20'
      params.type = '2'
      this.couponListParams = params;
    },

    methods: {
      onClickHeader(idx){
        this.selType = idx;
        this.couponListParams = Object.assign({}, this.couponListParams, {type: idx});
      },
      ruleDesc(){
        this.showCouponRule = true;
      },
      dataChange(data){
        this.requestData = data
      },
      getData(){
        this.$axios.CouponList(this.couponListParams).then(res => {
          const data = res.data;
          if (data && data.code == 0) {
            this.requestData = data.data.list;
          }
        })
      },
      onExchange(){
        let value = this.exchangeValue || '';
        let that = this;
        if(value!=''){
          let params = {  
            code: value
          }
          this.$axios.CouponExchange(params).then(res => {
            const data = res.data;
            if (data && data.code == 0) {
              this.$message.success('兑换成功');
              this.getData();
            }else{
              this.$message.error('兑换失败');
            }
          }).catch(error => {
            console.error(error);
          })
          this.exchangeValue = '';
        } else {
          this.$message.info('请输兑换内容。');
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .cont-header{
      line-height: 66px;
      border-bottom:1px solid #F0F4F5;
      span{
          display: inline-block;
          line-height: 66px;
          font-size: 16px;
          font-weight: 500;
          color: #333333;
      }
      span[selected]{
          color: #FF7B13;
          border-bottom: 2px solid #FF7B13;
      }
      .header_link:hover{
          cursor: pointer;
          color: #FF7B13;
          border-bottom: 2px solid #FF7B13;
      }
  }
  
  .cont{
      width: 90%;
      margin: 0 auto;
      padding-top:24px;
  }
  /deep/{
    .ant-modal {
      top: 220px;
    }
  }
  .coupon-gray{
    -webkit-filter:grayscale(100%);
    -moz-filter:grayscale(100%);
    -ms-filter:grayscale(100%);
    -o-filter:grayscale(100%);
    filter:grayscale(100%);
    filter:url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1)
  }
  
</style>
