<template>
  <div class="coupon-list">
    <div>
      <div class="coupon-list-wrap flex cen-start">
          <div class="coupon-item  flex cen-start" v-for="(item,i) in requestData" :key="i"  :class="item.type==3 || item.type==4 ?'coupon-gray':''" >
            <div class="left flex cen-center cro">
              <div class="cro_center"></div>
              <div v-if="item.coupon_type == '2'">
                  <div>
                    <span style="font-size: 13px;">¥</span>
                    <span style="font-size: 25px;font-weight:600;">{{item.money}}</span>
                  </div>
                  <div>
                    <span :class="item.type==3 || item.type==4 ?'c-gray':''" >{{item.threshold>0?`满${item.threshold}元使用`:'无门槛使用'}}</span>
                  </div>
              </div>
              <div v-else>
                <div>
                  <span style="font-size: 25px;font-weight:600;">{{item.discount}}折</span>
                </div>
                <div>
                  <span :class="item.type==3 || item.type==4 ?'c-gray':''" >{{item.threshold>0?`满${item.threshold}元使用`:'无门槛使用'}}</span>
                </div>
              </div>
            </div>
            <div class="right cro">
              <div class="right-top">
               <span style="font-size: 16px;color:#333333;">全曲库通用</span>
              </div>
              <div class="right-bottom" :class="item.type==3 || item.type==4 ?'c-gray':''" >
                <span style="font-size:12px;">{{item.begin_time}}-{{item.end_time}}</span>
              </div>
              <div class="right-bottom">
                <span class="ft12 use-btn" @click="useCoupon(item)">立即使用 <img src="@/assets/images/web/coupon/use_now.svg"/> </span>
              </div>
            </div>
            <div class="coupon-status" v-if="item.type==3 || item.type==4">
              <img :src="item.type==3?useCouponImg:expiredCouponImg " alt="">
            </div>
          </div>
      </div>

    </div>
  </div>
</template>

<script>
  import useCouponImg from '@/assets/images/use-coupon-icon.png'
  import expiredCouponImg from '@/assets/images/expired-coupon-icon.png'

  export default {
    data() {
      return {
        useCouponImg,
        expiredCouponImg,
      }
    },
    

    props: {
      type: String,
      requestData: Array,
    },


    components: {
    },

    methods: {
      useCoupon(item){
        if(item.type !== 2) return;
         this.$router.push(`/allMusic`);
      },     
    }
  }
</script>

<style lang="scss" scoped>

  .coupon-list{
      .top-operation{
        margin-top: $space-ver-md;
        .desc-wrap{
          cursor: pointer;
            .shuoming-icon{
              font-size: 16px;
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
            .coupon-desc{
              margin-right: $space-base;
            }
        }
      
      }
      .coupon-list-wrap{
        width: 100%;
        margin-top: 36px;
        flex-wrap: wrap;
        // justify-content: space-between;
        align-items: center;
        .coupon-item{
          min-width: 250px;
          width: 32%;
          margin-right: 10px;
          // &:nth-child(3n) {
          //   margin-right: 0;
          // }
          margin-bottom: 30px;
          height: 96px;
          color: #FF9751;
          .left{
            width: 40%;
            flex: 0 0 40%;
            height: 100%;
            background: #FF7B13;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            span{
              color: #FFFFFF;
              font-size: 12px;
            }
          }
        } 
        .right{
            width: 60%;
            height: 100%;
            border-top-right-radius:4px ;
            border-bottom-right-radius: 4px;
            text-align: left;
            padding-left: 6%;
            padding-top:14px;
            .right-bottom{
              font-size: 12px;
              color: #666666;
              margin-top: 6px;
              .use-btn{
                cursor:pointer;
              }
            }
            .c-gray{
              color:  rgba(0, 0, 0, 0.35)!important;
            }
          }
          .coupon-status{
            width: 60px;
            height: 60px;
            position:absolute;
            bottom: 8px;
            right: 5px;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .coupon-gray{
            -webkit-filter:grayscale(100%);
            -moz-filter:grayscale(100%);
            -ms-filter:grayscale(100%);
            -o-filter:grayscale(100%);
            filter:grayscale(100%);
            filter:url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
            filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1)
          }
        .cro {
            border: 1px solid #FF9751;
            position: relative;
        }
        .cro_center{
          position: absolute;
          width: 10px;
          height: 10px;
          border: 1px solid #fff;
          z-index: 1;
          background: #fff;
          top: 43px;
          left: -5px;
          border-radius: 10px;
        }
      }
  }
  

  

  .operation {
    button {
      height: auto;
    }
  }

  @media screen and (max-width: $screen-width-md) {

      .top-operation{
       justify-content: space-between;
      
      }
    .coupon-list{
       .coupon-list-wrap{
         justify-content: center;
        .coupon-item{
          width: 25rem;
          margin-right: 0;
        }
       }
    }
    .good-item-right {
      width: 100%;
      flex-direction: column;
      margin-top: $space-ver-sm;

      .price {
        margin-bottom: $space-ver-sm;
      }
    }
  }
</style>
