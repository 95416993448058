<template>
  <ng-dialog
    class="coupon-rule-dialog"
    :visible="show"
    pcWidth='500px'
    :maskClosable="false"
    :footer="null"
    @close="onClose"
  >
    <div class="con">
      <h2 class="rule-tit">优惠券规则说明</h2>
      <div>
        <p>1.优惠券目前有满减券和折扣券两种，且只能在有效时间内使用，超出有效期无法使用； </p>
        <p>2.优惠券只能在订单结算时抵扣金额，不能兑换现金，每个订单最多使用一张优惠券；</p>
        <p>3.优惠券不能与其他优惠共享，订单中包含特价音乐时，特价音乐不参与优惠；</p>
        <p>4.已使用优惠券的订单，超时未支付后订单自动失效，优惠券退回账户，所退优惠券的有效起止时间不变不推迟； </p>
        <p> 5.优惠券抵扣的金额不能开具发票； </p>
        <p>6.最终解释权归Spark Music所有，如有疑问请咨询在线客服。</p>
          
      </div>
    </div>
  </ng-dialog>
</template>

<script>
  import NgDialog from '@/components/NgDialog.vue'
  import Login from "@/pages/components/login/Login.vue";
  import FindPwd from "@/pages/components/login/FindPwd.vue";
  import Register from "@/pages/components/login/Register.vue";

  import {dialogMixins} from "@/mixins/index";
  import {mapMutations} from "vuex";

  export default {
    data() {
      return {
        show: false,
      };
    },

    watch: {
     
    },

    components: {
      Login,
      Register,
      FindPwd,
      NgDialog,
    },

    props: {
    },

    mixins: [dialogMixins],

    methods: {
      ...mapMutations(["changeLoginAndRegisterDialog"]),


      showLoginDialog(bool) {
        this.changeLoginAndRegisterDialog(bool);
      },

      onClose() {
        this.$emit('close');
      },
      handleSubmit(){
        console.log('handleSubmit: ', );

          this.form.validateFields((err, fieldsValue) => {
            console.log('err: ', err);
          if (err) {
            return;
          }

          let param = fieldsValue;


          this.$axios.CouponExchange(param).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              this.$message.success('兑换成功');
              this.onClose()
              this.$emit('reload')
            }else{
               this.$message.error('兑换失败');
            }

          }).catch(error => {

            console.error(error);
          })
        })
      }
     
    },
  };
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
  .coupon-rule-dialog {
    .ant-modal-body {
      padding: 24px 50px !important;
      .header-con{
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        padding-bottom: 16px;
        .header{
          font-size: 28px;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.85);
        }
        span{
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          margin-top: 8px;
        }
      }
      .con{
        margin-top: 14px;
        .rule-tit{
          margin-bottom: 16px;
          font-size: 18px;
        }
        .code-label{
          font-size: 14px;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.85);
        }
      }
      .btn-wrap{
          // margin-top: 32px;
      }
    }

    .ant-drawer-body-inner {
      padding-top: 60px !important;
    }

    .logon-tabs-wrap {
      .ant-tabs-bar {
        margin-bottom: 64px;
      }

      .ant-tabs-tab {
        font-size: 18px !important;
        color: rgba(0, 0, 0, 0.55);
      }

      .ant-tabs-tab-active {
        font-size: 24px !important;
        color: rgba(0, 0, 0, 0.85);
      }

      .ant-tabs-ink-bar {
        background-color: rgba(0, 0, 0, 0.85) !important;
      }
    }
  }
</style>
