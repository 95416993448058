<script>
  import {Load} from '@/utils/variables'
  const createMore = (h, event, loadStatus) => {
    let isEmpty = loadStatus === Load.empty || loadStatus === Load.error;
    let noMore = loadStatus === Load.noMore;
    let disabled = loadStatus === Load.loading;
    return !isEmpty ? (noMore
      ? h('span', {
        class: ['no-more-box font-color-sub flex cen-center active-class'],
        domProps: {
          innerHTML: '没有更多'
        },
      })
      : h('a-button',
        {
          class: ['more-box-wrap flex cen-center active-class'],
          on: event,
          props: {
            disabled: disabled
          }
        },
        [
          h('span', '查看更多'),
          h('div',
            {class: ['more-icon-box flex cen-center']},
            [
              h('iconFont',
                {props: {type: 'iconarrow-line-bottom'}}
              )
            ]
          )
        ]
      )) : []
  }

  

  let isFirstChangeParams = true;

  export default {
    data() {
      return {
        tLoading: false,
        newParams: {},
        data: [],
        visibleDrawer: false,
        loadStatus: Load.init,
        curInfo: {}
      }
    },

    render(h) {
     
      let More = null;

      const props = {...this.$attrs, ...this.$props};

      if (props.isMore) {
        More = createMore(h, {click: this.onClickMore}, this.loadStatus);
      }

      return (
        <div class="more-wrap">
          {props.isMore && More ? More : null}
        </div>
      )
    },

    props: {
      request: Function,
      params: Object,
      load: Boolean,
      reload: Boolean,
      // 数据请求之后，转换数据
      transformData: Function,
      hasPagination: {
        type: Boolean,
        default: true
      },
      changeData: {
        type: Array,
        default() {
          return []
        }
      },
      keyId: String,
      dataList: Array,
      isImmediate: {
        type: Boolean,
        default: true
      }
    },

    watch: {
      params: {
        handler(newVal) {
          if (!this.isImmediate && isFirstChangeParams) {
            isFirstChangeParams = false;
            return false;
          }

          let params = Object.assign(this.newParams, newVal);

          this.newParams = params;

          this.newParams.page = 1;

          this.getData(this.newParams, true);
        },
        deep: true,
        immediate: true
      },

      dataList: {
        handler(newVal) {
          if (!this.request || typeof this.request !== 'function') {
            typeof this.transformData === 'function' && (newVal = this.transformData(newVal));

            this.data = newVal || []

            this.$emit('dataChange', this.data);
          }
        },
        immediate: true
      },

      load(newVal) {
        if (newVal) {
          this.getData(this.newParams)

          setTimeout(() => {
            this.$emit('update:load', false);
          }, 100);
        }
      },

      reload(newVal) {
        if (newVal) {
          this.reloadData();

          this.$emit('update:reload', false);
        }
      },

      changeData(newVal) {
        if (newVal && newVal.length > 0) {
          let list = this.data;
          let changeIds = newVal.map(item => item[this.keyId]);
          let changeDir = newVal.reduce((prev, next) => {
            let key = next[this.keyId];
            key && (prev[next[this.keyId]] = next);
            return prev;
          }, {})

          list = list.map(item => {
            let key = item[this.keyId];

            if (key && ~changeIds.indexOf(key)) {
              return changeDir[key] ? changeDir[key] : item;
            }

            return item;
          })

          this.data = [...list];
        }
      }
    },

    components: {
    
    },

    methods: {
      onClickMore() {
        this.newParams.page += 1;

        this.getData(this.newParams);
      },

      onClickMobileTableMore(scope) {
        this.curInfo = scope;

        this.visibleDrawer = true;
      },

      onCloseDrawer() {
        this.visibleDrawer = false;
      },

      reloadData() {
        this.newParams.page = 1;

        this.getData(this.newParams, true);
      },

      getData(params, reload) {
        if (typeof this.request === 'function') {
          this.tLoading = true;

          this.loadStatus = Load.loading;

          this.request(params).then(res => {
            let data = res.data;

            if (data && data.code == 0) {
              let inner = data.data;
              let total = inner.total, pagesize = inner.page_size, page = this.newParams.page;
              let curTotal = pagesize * page;

              let list = data.data ? data.data.list : [];

              typeof this.transformData === 'function' && (list = this.transformData(list));

              this.data = !reload ? this.data.concat(list) : list;

              this.loadStatus = total == 0 ? Load.empty : total <= curTotal ? Load.noMore : Load.load;

              this.$emit('dataChange', this.data);
            } else {
              this.loadStatus = Load.error;
              this.newParams.page -= 1;
            }

            this.tLoading = false;
          }).catch(() => {
            this.loadStatus = Load.error;
            this.tLoading = false;
            this.newParams.page -= 1;
          })
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/scss/dialog.scss";

  .no-more-box {
    margin-top: 16px;
  }

  .more-box-wrap {
    display: block;
    margin: 24px auto 0;
    cursor: pointer;
    width: 150px;
    text-align: center;
    border: 0;

    .more-icon-box {
      display: inline-block;
      width: 36px;
      height: 36px;
      line-height: 36px;
      color: #fff;
      text-align: center;
      margin-left: 8px;
      background: rgba(0, 0, 0, 0.85);
      border-radius: 50% 50%;
    }

    &[disabled] {
      background: none;

      .more-icon-box {
        background: rgba(0, 0, 0, 0.45);

        &:hover {
          background: rgba(0, 0, 0, 0.45);
        }
      }
    }
  }

  .table-mobile-more {
    font-size: 24px;
  }

  .ng-table-drawer.ant-drawer-bottom {
    /deep/ {
      .ant-drawer-body {
        padding-top: 0;
      }

      .drawer-list-item {
        padding-top: 18px;
        padding-bottom: 18px;
        border-bottom: 1px solid $border-color;

        &:last-child {
          border: none;
        }
      }
    }
  }

  @media screen and (max-width: $screen-width-md) {
    .ng-table-drawer {
      /deep/ {
        .ant-drawer-body {
          font-size: $font-size-md;
          padding: 0 10px;
        }
      }
    }
  }
</style>
